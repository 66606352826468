import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import HomeLogos from '../components/HomeLogos';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';
import Container from '../components/Container';
import ImageText from '../components/ImageText';
import ImageTextDescription from '../components/ImageTextDesctiption';
import PrivateSystemSection from '../components/PrivateSystemSection';
import Scen9 from '../images/scen-9.svg';

const SCEN_DATA = [
  {
    id: 'private-1',
    iconIndex: 15,
    title: '客户关系管理',
    description: '私域流量运营并非流量收割，流量变现的前提是客户关系的持续运营',
  },
  {
    id: 'private-2',
    iconIndex: 32,
    title: '生命周期旅程',
    description: '通过对客户生命周期旅程的设计，进行私域流量运营的策略拆解',
  },
  {
    id: 'private-3',
    iconIndex: 11,
    title: '客户终身价值',
    description: '通过对客户的持续运营和孵化，推动复购和转介绍，实现价值最大化',
  },
  {
    id: 'private-4',
    iconIndex: 62,
    title: '品牌IP打造',
    description: '私域品牌IP的设定源于对客户的真正了解，塑造让客户认同的人设',
  },
  {
    id: 'private-5',
    iconIndex: 51,
    title: '自动化内容推送',
    description: '大规模跨平台的运营需要自动化的手段实现营销内容的精准分发',
  },
  {
    id: 'private-6',
    iconIndex: 63,
    title: '良性的双向沟通',
    description: '精准快速地满足客户的需求，并及时得到反馈，形成良性的互动',
  },
];

export default () => {
  return (
    <Layout current="scens" subCurrent="privatetraffic">
      <CommonPageBanner
        title="私域流量运营"
        description="私域流量是企业可以有效和主动触达的客户群体，本质是高质量的营销触达和客户关系管理。在获客成本日益高企的流量去中心化时代，越来越多的品牌开始意识到私域流量的DTC运营模式对于获客、留存和转化的重要性。"
        imgName="private"
      />
      <PrivateSystemSection />
      <TitledVerticalBoxes title="私域流量运营的策略核心" sectionId="private">
        {SCEN_DATA.map((item) => (
          <Col lg={8} md={12} sm={12} xs={24} className="icon-text-col" key={item.id}>
            <VerticalBox {...item} />
          </Col>
        ))}
      </TitledVerticalBoxes>
      <div className="content-list-wrapper private">
        <section className="image-text-section blue">
          <Container>
            <ImageText image={Scen9} imgWidth={435} imgHeight={369} title="全链路营销 — 私域流量的破圈运营" imageSize={10}>
              <ImageTextDescription
                label="全链路闭环"
                content="打通从投放到营销全流程再到交易环节，公域+私域的完整营销链路"
              />
              <ImageTextDescription
                label="私域流量池扩容"
                content="通过全渠道数据接入和丰富的扩展能力，对流量池进行持续扩容"
              />
              <ImageTextDescription
                label="数据流转"
                content="建立CDP客户数据平台，进行用户画像和洞察，形成精准DMP人群包"
              />
              <ImageTextDescription
                label="重定向广告"
                content="通过实时重定向引擎实现私域事件自动触发Retargeting广告重定向"
              />
            </ImageText>
          </Container>
        </section>
      </div>
      <HomeLogos />
    </Layout>
  );
};
