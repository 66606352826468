import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Fade from 'react-reveal/Fade';
import Container from './Container';
import Scen10 from '../images/scen-10.svg';

const imgHeight = 324;
const imgWidth = 324;
export default () => {
  const [isMobile, setIsMobile] = useState(false);
  const [displayImgHeight, setDisplayImgHeight] = useState(imgHeight);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setDisplayImgHeight(((window.innerWidth - 30) * 0.8 * imgHeight) / imgWidth);
    }
  }, []);
  return (
    <section id="private-system-card" className="private-system-card">
      <Container>
        <div className="card-wrapper">
          <h3 className="content-title">我们建议您搭建企业级的私域流量运营体系</h3>
          <Row>
            <Col lg={10} md={24} sm={24} xs={24} className="image-col">
              <Fade
                left={!isMobile}
                top={isMobile}
                duration={600}
                delay={200}
                distance="30px"
              >
                <img src={Scen10} width={imgWidth} height={displayImgHeight} className="img-fluid" alt="content" />
              </Fade>
            </Col>
            <Col lg={14} md={24} sm={24} xs={24} className="content">
              <Fade
                right={!isMobile}
                bottom={isMobile}
                duration={1000}
                delay={200}
                distance="30px"
              >
                <div className="content-inner">
                  <p>
                    私域流量不仅仅存在于传统意义上的微信体系，而是存在于可与客户触达的所有渠道平台，分散在小程序、各类会员系统、支付宝生活号、自媒体号、线下数据表、SCRM客户管理系统等渠道。
                  </p>
                  <p>
                    Convertlab企业级私域流量运营解决方案，具备跨平台的流量整合及应用能力，能够统一管理全渠道私域用户的营销互动，并具有企业级数据安全和个人信息保护机制。
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
